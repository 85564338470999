@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

@keyframes flicker {
    0% {
        opacity: 0.9;
    }

    10% {
        opacity: 0.8;
    }

    25% {
        opacity: 1;
    }

    50% {
        opacity: 0.8;
    }

    75% {
        opacity: 1;
    }
    85% {
        opacity: 0.9;
    }

    100% {
        opacity: 1;
    }
}

.tech-button {
    height: 50px;
    min-width: 200px;
    font-size: 1.1rem;
    font-weight: 300;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    padding: 20px;
    --b: 2px;
    /* thickness of the border */
    --c: rgb(255, 255, 255);
    /* color of the border */
    --w: 10px;
    /* width of border */

    border: 1px solid rgba(255, 255, 255, 0.4);
    outline: var(--b) solid #0000;
    /* space for the border */
    --_g: #0000 90deg, var(--c) 0;
    --_p: var(--w) var(--w) border-box no-repeat;
    background:
        conic-gradient(from 90deg at top var(--b) left var(--b), var(--_g)) 0 0 / var(--_p),
        conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 / var(--_p),
        conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% / var(--_p),
        conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g)) 100% 100% / var(--_p);
    @media screen and (max-width:480px) {
        width: 100%;
    }
}
.token-address{
    color: rgba(255, 255, 255, 0.8);
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    text-transform: none;
    width: auto;
    letter-spacing: 0.1rem;
}
.token-address svg{
    stroke: rgba(255, 255, 255, 0.8);
    stroke-width: 1.5;
    stroke-linejoin: round;
    stroke-linecap: round;
}
.tech-button:hover {
    color: rgb(255, 255, 255);
    text-shadow: 0px 20px 20px #00fff237;

    border-color: rgba(127, 255, 212, 0.6);
    --b: 2px;
    /* thickness of the border */
    --c: rgb(0, 255, 225);
    /* color of the border */
    --w: 10px;
    /* width of border */
    --_g: #0000 90deg, var(--c) 0;
    --_p: var(--w) var(--w) border-box no-repeat;
    background:
        conic-gradient(from 90deg at top var(--b) left var(--b), var(--_g)) 0 0 / var(--_p),
        conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 / var(--_p),
        conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% / var(--_p),
        conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g)) 100% 100% / var(--_p);

    background-color: #00989b25;
    animation: flicker 1s infinite;
}
.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
    /* If you want dots under the hoverable text */
}

.tooltip .tooltiptext {
    visibility: hidden;
    max-width: 200px; /* Control max width */
    background-color: rgba(0, 0, 0, 0.8); /* Slightly transparent black */
    color: #fff;
    text-align: center;
    padding: 8px 10px; /* Adjusted padding */
    border-radius: 6px;
    font-size: 0.9rem; /* Adjust font size for readability */
    position: absolute;
    top: -32px; /* More space above the button */
    left: 50%;
    transform: translateX(-50%); /* Center horizontally */
    z-index: 1;
}

/* Show the tooltip text when you mouse over the tooltip container */
.tooltip:hover .tooltiptext {
    visibility: visible;
}

.tooltip .tooltiptext::after {
    content: " ";
    position: absolute;
    top: 100%;
    /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: black transparent transparent transparent;
}