@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fredoka:wght@300..700&family=Rajdhani:wght@300;400;500;600;700&family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
html, body {
    width: 100%;
    height: 100%;
    overflow-x: hidden; 
    background: #10121B;
    font-family: "Titillium Web", sans-serif; 
    background-image: radial-gradient(rgb(27, 47, 50) 1px, transparent 0);
    background-size: 40px 40px;
    background-position: -19px -19px;
}
#logo-svg {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding-top: 20px;
}
#email-screen {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    min-height: 100vh;
}

#logo-header {
    padding-top: 20px;  /* Adjust the value as needed */
    text-align: center;
}

#content-wrapper {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    padding: 20px;
    margin: auto;
}

.left-column, .right-column {
    flex: 1;
    padding: 20px;
}

.left-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.right-column {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.airdrop-image {
    max-width: 100%;
    height: auto;
}

.instruction-text {
    margin-top: 20px;
    color: #fff;
    text-align: center;
}

.header-title {
    font-family: "Rajdhani", sans-serif;
    font-weight: 700;
    font-size: 3rem;
    text-transform: uppercase;
    letter-spacing: .5rem;
    color: rgb(0, 255, 225);
    text-align: center;
    animation: flicker 0.5s infinite;
}

@media screen and (max-width: 480px) {
    .button-group {
        flex-direction: column; /* Stack buttons vertically */
        width: 100%; /* Ensure the button group takes full width */
    }
    .button {
        max-width: 100%; /* Full width for mobile */
        width: 100%; /* Ensure each button stretches fully */
        height: auto; /* Auto height based on content */
        padding: 12px 20px; /* Adjust padding to ensure enough space vertically and horizontally */
        font-size: 1rem; /* Adjust font size if necessary */
        margin-bottom: 10px; /* Space between stacked buttons */
        display: flex; /* Use flex to center content */
        justify-content: center; /* Center horizontally */
        align-items: center; /* Center vertically */
    }
    #content-wrapper {
        flex-direction: column-reverse; /* Reverse order of columns for mobile */
        align-items: center; /* Ensure alignment is centered for the flex container */
    }
    .left-column, .right-column {
        width: 100%; /* Full width for each column on mobile */
        padding: 10px; /* Adjust padding */
        align-items: center; /* Center align items in columns */
    }
    .header-title {
        font-size: 1.8rem; /* Smaller font size for mobile */
    }
}

.button, .signup-button {
    width: auto; /* Auto width to contain content */
    max-width: 100%; /* Restrict maximum width */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; /* Prevent text spilling */
}
.button {
    display: flex; /* Use flexbox for centering */
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    color: rgba(255, 255, 255, .85);
    font-size: 1.1rem;
    font-weight: 300;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    padding: 8px 20px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    background:
        conic-gradient(from 90deg at top 2px left 2px, #0000 90deg, rgb(255, 255, 255) 0) 0 0 / 10px 10px border-box no-repeat,
        conic-gradient(from 180deg at top 2px right 2px, #0000 90deg, rgb(255, 255, 255) 0) 100% 0 / 10px 10px border-box no-repeat,
        conic-gradient(from 0deg at bottom 2px left 2px, #0000 90deg, rgb(255, 255, 255) 0) 0 100% / 10px 10px border-box no-repeat,
        conic-gradient(from -90deg at bottom 2px right 2px, #0000 90deg, rgb(255, 255, 255) 0) 100% 100% / 10px 10px border-box no-repeat;
    flex-grow: 1;
    /*max-width: 200px;*/
    height: 50px; /* Fixed height for uniformity */
}

.button:hover {
    color: rgb(255, 255, 255);
    text-shadow: 0px 20px 20px #00fff237;
    border-color: rgba(127, 255, 212, 0.6);
    background-color: #00989b25;
    background:
        conic-gradient(from 90deg at top 2px left 2px, #0000 90deg, rgb(0, 255, 225) 0) 0 0 / 10px 10px border-box no-repeat,
        conic-gradient(from 180deg at top 2px right 2px, #0000 90deg, rgb(0, 255, 225) 0) 100% 0 / 10px 10px border-box no-repeat,
        conic-gradient(from 0deg at bottom 2px left 2px, #0000 90deg, rgb(0, 255, 225) 0) 0 100% / 10px 10px border-box no-repeat,
        conic-gradient(from -90deg at bottom 2px right 2px, #0000 90deg, rgb(0, 255, 225) 0) 100% 100% / 10px 10px border-box no-repeat;
}
.button-container, .button-group {
    display: flex;
    justify-content: center;
    gap: 5px; /* Space between buttons */
    flex-wrap: wrap; /* Allow buttons to wrap */
}
.input-container input {
    width: calc(100% - 20px); /* Full width minus padding */
    padding: 10px; /* Adequate padding for tap targets */
}
.tech-box, .techbox {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
    padding: 10px 0;
    width: 100%;
}

.highlight-text {
    color: rgb(0, 255, 255);
    text-decoration: none;
}

@keyframes flicker {
    0% {
        opacity: 0.9;
    }
    10% {
        opacity: 0.8;
    }
    25% {
        opacity: 1;
    }
    50% {
        opacity: 0.8;
    }
    75% {
        opacity: 1;
    }
    85% {
        opacity: 0.9;
    }
    100% {
        opacity: 1;
    }
}

.tech-box {
    padding: 20px;
    transition: 0.2s all;
    --b: 2px;
    /* thickness of the border */
    --c: rgb(255, 255, 255);
    /* color of the border */
    --w: 10px;
    /* width of border */
    /*border: 1px solid rgba(255, 255, 255, 0.4);*/
    outline: var(--b) solid #0000;
    /* space for the border */
    --_g: #0000 90deg, var(--c) 0;
    --_p: var(--w) var(--w) border-box no-repeat;
    background: conic-gradient(from 90deg at top var(--b) left var(--b), var(--_g)) 0 0 / var(--_p), conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 / var(--_p), conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% / var(--_p), conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g)) 100% 100% / var(--_p);
}

.tech-box p {
    margin-bottom: 40px;
    color: rgb(255, 255, 255);
}

hr {
    border: 0;
    clear: both;
    display: block;
    margin: 2.4rem auto;
    text-align: center;
    width: 100
}

h1 {
    margin: 0;
}

.divider {
    position: relative;
    background: rgba(127, 255, 212, 0.6);
    height: 1px;
    overflow: hidden;
    width: 100%;
    margin: 0px 0px;
}

.bar-left::before {
    background: rgba(127, 255, 212, 0.6);
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
   /* margin-top: 4px;*/
    height: 4px;
    width: 40px;
}

.signup-button {
    margin-left: auto;
    margin-right: auto;
    --notchSize: 20px;
    text-transform: uppercase;
    border: 0;
    outline: none;
    background: #ffc800;
    width: auto;
    padding: 20px 80px;
    color: black;
    font-weight: 600;
    font-size: 1.2rem;
    transition: 0.5s transform;
    clip-path: polygon(0% var(--notchSize), var(--notchSize) 0%, 100% 0%, 100% 0%, 100% calc(100% - var(--notchSize)), calc(100% - var(--notchSize)) 100%, 0% 100%, 0% 100%);
}
.signup-button-disabled {
    font-family: "Rajdhani", sans-serif;
    --notchSize: 20px;
    text-transform: uppercase;
    border: 0;
    outline: none;
    background: rgba(0, 255, 255, 0.2);
    width: auto;
    padding: 20px 80px;
    color: rgba(255, 255, 255, .85);
    font-weight: 600;
    font-size: 1.4rem;
    transition: 0.5s transform;
    pointer-events: auto; 
    cursor: not-allowed; 

    clip-path:
        polygon(0% var(--notchSize),
            var(--notchSize) 0%,
            100% 0%,
            100% 0%,
            100% calc(100% - var(--notchSize)),
            calc(100% - var(--notchSize)) 100%,
            0% 100%,
            0% 100%);

}

.signup-button:hover {
    color: white;
    background-color: rgba(0, 255, 255, 0.2);
}

input {
    width: 100%;
    height: 40px;
    margin: 20px 0;
    background-color: transparent;
    border: none;
    border-bottom: .5px solid rgba(255, 255, 255, 0.5);
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.2rem;
    /* text-transform: uppercase; */
    letter-spacing: 0.2rem;
    padding: 0 10px;
}

input:focus {
    border: none;
    outline: none;
    border-bottom: 1px solid white;
}
.button-group {
    display: flex;
    justify-content: center; 
    gap: 20px;
}
.button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
}

.confirmation-message {
    margin-top: 20px;
    color: #ffc800; 
    font-size: 1rem;
    text-align: center;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #10121B;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 90%;
  }